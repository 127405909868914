.Search {
  width: 25rem;
  .SearchIcon {
    color: var(--blings_pink);
    opacity: 0.6;
    font-size: 1.2rem;
  }
  .SearchInput {
    padding: 6px 18px;
    input {
      font-size: 14px;
      margin-left: 6px;
    }
  }
}
