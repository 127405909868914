.DynamicEditForm {
  border-radius: 4px;
  & > .ant-form-item {
    .TopTextBox {
      color: var(--blings_blue);
      text-align: end;
      margin-right: 0.2rem;
    }
    margin-bottom: 0rem;
  }
  .ant-form-item {
    margin-bottom: 0.5rem;
  }
  .button-secondary {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
    > button {
      font-weight: 700;
      border: 1px solid #7f8899;
      color: #7f8899 !important;
      border-color: #cdd5e5 !important;

      &:hover {
        color: #e3355f !important;
        border-color: #ffccd8 !important;
      }

      &:is([disabled]) {
        color: white !important;
        border: 1px solid #cdd5e5 !important;
      }
    }
  }
  .type-explanation {
    color: #8b8d97;
    font-size: 14px;
  }
  .TextArea {
    background-color: white;
    border: 1px solid rgba(255, 21, 77, 0.5);
    resize: none;
  }
  .outside-blingsInput {
    display: flex;
    align-items: center;
    label {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }
  .text-save {
    display: flex;
    align-items: center;
  }
  .BlingsInput {
    border-radius: var(--ant-border-radius);
    border: solid 1px var(--blings_input_gray);
    padding: 7px 7px 2px 0px;
    .left {
      .input {
        border: none !important;
      }
    }
  }
}

.variant-add-container {
  cursor: pointer;
  .add-ai-icon {
    display: flex;
    align-items: center;
  }
  display: flex;
  margin-top: -0.9 rem;
  margin-bottom: 1rem;
  align-items: center;
  gap: 0.2rem;
}
.variant-viewport {
  .ant-form-item {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .input-main-container {
    margin-bottom: 0.5rem;
  }
}
