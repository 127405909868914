.SdkIntegration {
  .code-comment {
    opacity: 0.6;
  }
  .ant-card-body {
    .ant-btn {
      padding: 0.3rem;
    }
  }
  .html-download {
    margin-top: 1rem;
  }
}
