.use-case-main-wrapper {
  display: flex;
  height: 100%;
  border-radius: 0;
  column-gap: 32px;
  overflow: hidden;

  .next-btn-wrapper {
    display: flex;
    width: 100%;
  }
  .next-step-btn {
    height: 42px;
    border-radius: 6px;
    color: #ffffff;
    background: #2e2e2e;
    margin-right: 16px;
    width: 100%;
  }
}

.steps-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 34px;
  width: 100%;
}
.ant-modal-content {
  background: #ffffff !important;
  padding: 16px !important;
  .ant-modal-close {
    margin: 0;
  }
}
.use-case-left-side {
  display: flex;
  justify-content: center;
  width: 50%;
  height: 100%;
  position: relative;
  border-radius: 12px;
}

.use-case-right-side {
  display: flex;
  flex-direction: column;
  width: 50%;
  overflow: hidden;
  justify-content: space-between;
  align-items: flex-start;
  background: #ffffff;

  .customize-use-case-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .customize-use-case-title {
    display: flex;
    flex-direction: column;
    gap: 16px;
    h1 {
      color: #1b2b4b;
      margin: 0;
      font-family: Inter;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    p {
      margin: 0;
    }
  }
  .tag-list {
    gap: 8px;
    display: flex;
    align-items: center;
    height: 24px;

    width: 100%;
    overflow: auto hidden;

    &::-webkit-scrollbar-thumb {
      background: none !important;
    }
    &::-webkit-scrollbar {
      height: 6px !important;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: #babac0 !important;
      }
    }
  }
  .usecase-tag {
    height: 24px;
    padding: 0px 8px;
    border-radius: 20px;
    border: none;
    color: #4d5566;
    background-color: #f2f4f8;
    font-family: Inter;
    font-size: 10px;
    font-weight: 700;
    text-align: left;
    line-height: 24px;
    white-space: nowrap;
  }
  .about-template {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;

    h3 {
      color: #404959;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 700;
      line-height: 17px;
      text-align: left;
    }

    h4 {
      color: #404959;
      font-family: "Inter";
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      text-align: left;
      margin-bottom: 16px;
    }
  }
  .use-template-btn {
    width: 138px;
    height: 32px;
    padding: 0px 16px 0px 16px;
    gap: 10px;
    border-radius: 6px 0px 0px 0px;
    opacity: 0px;
    background: #262626;

    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .separator {
    border-bottom: 1px solid #cdd5e5;
    margin-top: 16px;
  }
}

.brand-customization-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 10px;

  label {
    color: #404959;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
  }

  .color-item-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .color-item-content {
      flex: 1 1 calc(50% - 16px);
      max-width: calc(50% - 16px);
      box-sizing: border-box;
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #404959;
        margin-bottom: 12px;
      }
      .color-item {
        display: flex;
        gap: 15px;
        border: 1px solid #cdd5e5;
        border-radius: 6px;
        align-items: center;
        height: 32px;

        input[type="color"] {
          all: unset;
          width: 66px;
          height: 32px;
          border-radius: 6px 0 0 6px;
          cursor: pointer;
        }

        label {
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #404959;
        }
      }
    }
  }
  .rounded-color-picker::-webkit-color-swatch {
    border-radius: 40px;
  }

  input[type="file"] {
    display: none;
  }

  .container-file-upload {
    display: flex;
    place-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    height: 68px;
    border: 1px solid #cdd5e5;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: rgba(27, 43, 75, 0.75);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.75);
    }

    &:hover img {
      opacity: 0.3;
    }

    img {
      cursor: pointer;
      max-width: 100%;
      max-height: 100%;
    }

    input {
      display: none;
    }
  }

  .help-icon {
    margin-left: auto;
  }
}

.hover-file-upload {
  display: none;
  flex-direction: column;
  align-items: center;
  position: absolute;

  svg {
    width: 30px;
    height: 30px;
    color: #ffffff;
  }

  span {
    font-weight: 700;
    font-size: 12px;
    color: #ffffff;
    line-height: 22px;
  }
}

.container-file-upload:hover .hover-file-upload {
  display: flex;
}

.apply-template {
  display: flex;
  align-items: center;
  gap: 16px;
}

.apply-btn {
  width: 67px;
  height: 32px;
  padding: 0px 16px 0px 16px;
  gap: 10px;
  border-radius: 6px 0px 0px 0px;
  opacity: 0px;
  background: #ffffff;
  color: #262626;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.reset-changes-btn {
  cursor: pointer;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 6px 0 0 6px;
  padding: 0;
  margin: 0;
  width: 100px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  border: none;
  padding: 0;
}
