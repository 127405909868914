#date-picker {
  width: 100%;
  .ant-picker {
    width: 100%;
  }
}

.input-main-container {
  display: flex;
  gap: 0.5rem;
  flex-grow: 1;
  margin-bottom: 1rem;
}
.BlingsInput {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: auto;
  overflow: auto;
  background-color: white !important;
  border: solid 1px var(--blings_input_gray) !important;
  border-radius: var(--ant-border-radius) !important;
  &:focus {
    border-color: var(--blings_input_focus) !important;
  }

  .left {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    padding-right: 3px;
    height: auto;
    overflow: auto;

    .label {
      display: flex;
      color: #a9b6c3;
      font-size: 10px;
    }

    .input {
      display: flex;
      height: 31px;
      max-height: 100px;
      width: 100%;
      font-size: 14px;
      resize: none;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.65);
      overflow: hidden;
      border: none !important;
      padding: 4px 11px;
      &:focus {
        outline: none;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
    overflow: hidden;
    .restore-button {
      cursor: pointer;
      .Restore {
        color: #212121;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        border-radius: 5px;
        background-color: #7d959c33;
        &:hover {
          color: var(--blings_pink);
          background-color: #fbe9ed;
        }
      }
      &.restore-disabled {
        cursor: default;
        .Restore {
          background-color: #a9b6c333;
          color: #cccccc;
        }
      }
    }

    .delete-button {
      cursor: pointer;
      .Restore {
        color: #212121;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        border-radius: 5px;
        background-color: #7d959c33;
        &:hover {
          color: var(--blings_pink);
          background-color: #fbe9ed;
        }
      }
    }

    .upload-button {
      cursor: pointer;
      .Upload {
        color: #7f8899;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        border-radius: 5px;
        background-color: none;
        width: 25px;
        height: 25px;
        &:hover {
          color: var(--blings_pink);
          background-color: #fbe9ed;
        }
      }
      &.disabled {
        cursor: default;
        .Restore {
          background-color: #a9b6c333;
          color: #cccccc;
        }
      }
      .ant-upload.ant-upload-btn {
        padding: 0;
        display: flex;
        height: auto;
      }
    }
  }
}
