.optimization-panel-container {
  display: flex;
  flex-direction: column;
  gap: 100px;

  .ai-enable-optimization-panel-header {
    display: flex;
    align-items: center;
    gap: 24px;

    > h3 {
      margin: 0;
      color: var(--blings_title_and_subtitle);
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .ai-enable-optimization-panel-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;

    > .ai-enable-optimization-panel-option {
      padding: 30px;
      background-color: var(--blings_pages_background);
      border-radius: 25px;
      border-color: var(--blings_card_border);

      > .ai-enable-optimization-panel-option-title {
        font-family: "Prompt";
        color: var(--blings_card_text_gray);
        font-weight: 800;
      }

      > p {
        font-family: "Inter";
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: var(--blings_card_text_gray);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
