.use-case-gallery {
  padding: 24px 16px;
  min-height: 100%;
  max-width: 100%;
  margin: 0 150px;
  height: 100%;
  background: #f2f4f8;
}

.use-case-modal {
  width: 900px;
  @media screen and (max-height: 800px) {
    max-width: 65%;
  }
  .ant-modal-content {
    width: 100%;
    min-width: 800px;
    .ant-modal-close-x {
      color: #1b2b4b;
    }
  }
}
.use-case-gallery-header {
  width: 100%;
  display: flex;
  justify-content: start;

  .back-btn {
    font-size: 14px;
    font-weight: 400;
    font-family: "Inter";
    color: #45464e;
    display: flex;
    align-items: center;
    background: none;
    padding-left: 0;

    &:hover {
      color: var(--blings_pink) !important;
      background: none !important;
    }
  }
}
