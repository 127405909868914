.JsEditor {
  position: relative;

  .OpenWindow {
    cursor: pointer;
    position: absolute;
    top: -1rem;
    right: 1rem;
  }
}

.JsEditorAndToolTipContainer {
  position: relative;

  .InfoTooltip {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.JsEditorModal {
  .ant-modal-content {
    background-color: #ffe8ee;
    .line-numbers {
      width: 15px !important;
    }
  }

  .ant-modal-footer {
    border: none;
  }
}
