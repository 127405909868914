.Description {
  margin: 1.5rem 0 1rem 0;
  display: flex;
  p {
    color: var(--blings_blue);
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
}
.Divider {
  margin: 0 !important;
}
.TextLayer {
  .modEditConnectorName {
    border: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
    outline: none !important;
    background: inherit !important;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 0;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .modEditConnectorName::-webkit-input-placeholder {
    /* WebKit browsers */
    font-weight: 200;
    color: #fff;
  }
  .modEditConnectorName:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-weight: 200;
    color: #fff;
  }
  .modEditConnectorName::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-weight: 200;
    color: #fff;
  }
  .modEditConnectorName:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    font-weight: 200;
    color: #fff;
  }
  .ant-collapse {
    border: none;
  }
  .Panel {
    transition: background-color 0.3s ease;
    &.ant-collapse-item-active {
      background: white;
    }
    &:hover {
      background: #f2f4f8;
    }

    .Panel-Header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .interactive-left {
        gap: 0.5rem;
        display: flex;
        .interactive-left-info {
          .expose-icon {
            display: flex;
          }
          display: flex;
          width: 5.5rem;
          gap: 0.5rem;
          align-items: center;
        }
      }

      .layer-type {
        white-space: normal;
        overflow: hidden;
        font-size: 12px;
      }
      .cta-name {
        min-width: fit-content;
        // width: 8rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 600;
        overflow: hidden;
        &.no-cta {
          opacity: 0.2;
        }
      }
      .left-extra {
        font-size: 12px;
        text-overflow: ellipsis;
        width: 7rem;
        white-space: nowrap;
        overflow: hidden;
        color: #8b8d97;

        .color-box {
          width: 8px;
          height: 8px;
          border-radius: 2px;
          margin-right: 0.3rem;
          display: inline-grid;
        }
      }

      .Main-Info {
        display: flex;
        gap: 0.2rem;
        flex-direction: column;
        align-items: flex-start;
      }
      .right-main-info {
        margin-right: 0.5rem;
        display: flex;
        gap: 0.2rem;
        font-size: 12px;
        flex-direction: column;
        width: 8rem;
      }
    }
    .right-extra {
      font-size: 12px;
      text-overflow: ellipsis;
      margin-right: 0.5rem;
      overflow: hidden;
      color: #8b8d97;
      width: 6rem;
      text-align: left;
      white-space: nowrap;
      .key-tag {
        display: flex;
        align-items: center;
        gap: 0.2rem;
        width: fit-content;
      }
    }
    // border-bottom: none;
    // border-radius: 12px;
    &:not(:last-child)::after {
      content: ""; /* This is necessary for the pseudo element to work. */
      display: block; /* This will put the pseudo element on its own line. */
      margin: 0 auto; /* This will center the border. */
      width: 100%; /* Change this to whatever width you want. */
      border-bottom: 1px solid #cdd5e5; /* This creates the border. Replace black with whatever color you want. */
    }
    border: none;
    & > div.ant-collapse-header {
      padding: 1.1rem 0.7rem 1.1rem 0.7rem !important;
      gap: 8px;
      align-items: center;
      .ant-collapse-expand-icon {
        display: none;
      }
    }
    // & > div.ant-collapse-content > .ant-collapse-content-box {
    //   padding: 0rem 1rem;
    // }
    background-color: #fff;
    // &.ant-collapse-item-disabled {
    //   background: #F2F4F5;
    //   border: 1px solid #BEC0CA;
    //   color: #bec0ca;
    // }
    // margin: 0.5rem 0rem;
    // border-radius: 8px;
    &:last-child {
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    &:first-child {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    // &.ant-collapse-item:hover {
    //   border-left: 4px solid rgba(255, 21, 77, 0.1);
    // }
    // &.ant-collapse-item-disabled:hover {
    //   border-left: 1px solid #BEC0CA;
    // }
    @keyframes growBorder {
      0% {
        width: 0;
        left: 50%;
      }
      100% {
        width: 95%;
        left: 2.5%;
      }
    }
    .ant-collapse-content-box {
      padding-bottom: 4px;
    }

    &.ant-collapse-item-active > div.ant-collapse-header {
      position: relative; // Ensures the pseudo-element is relative to the header
      margin-bottom: 1rem;

      &:after {
        content: "";
        display: block;
        margin: 0 auto;
        position: absolute;
        bottom: 0;
        left: 2.5%;
        width: 95%;
        border-bottom: 2px solid #f00;

        // Apply the animation
        animation: growBorder 0.3s forwards;
      }
    }
  }
}
