.EditView {
  margin: 0 0.8rem;
  .all-scenes {
    font-weight: 500;
  }
  #sceneName {
    color: var(--blings_pink);
    font-weight: 600;
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    &:first-child {
      margin-top: 0;
    }
  }
  .DynamicText {
    margin-top: 2rem;
  }
}

.LayersFromSceneView {
  height: 100%;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  .SceneName {
    font-weight: 500;
    color: #45464e;

    #scene {
      color: #8b8d97;
    }
    #name {
    }
  }
  .search-wrapper {
    display: flex;
    margin: 1.5rem;
    .ant-select {
      padding: 0;
    }
  }
  .ant-tabs {
    // padding-top: 1rem;
    padding-left: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
  }
  .ant-tabs-nav {
    border-radius: 12px;
    display: flex;
    margin: 0 1rem 0.5rem 1em;
    &::before {
      border-bottom: 1px solid #f7f8fa;
    }
    background-color: white; // Prevents overlap with content when scrolling
  }
  .ant-tabs-content-holder {
    margin-bottom: 1rem;
    overflow: auto;
    padding-right: 10px;
  }
  .ant-tabs-nav-wrap {
    justify-content: flex-start;
    border-bottom: solid 1px #cdd5e5;
    margin-right: 5px;
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        width: 6.5rem;
        justify-content: center;
      }
    }
  }
}
#PublishButton {
  display: flex;
  justify-content: space-between;
  margin: 1rem 1.5rem;
  align-items: center;
  button {
    &:is([disabled]) {
      color: white;
    }
  }
}
.SceneName {
  margin: 2rem 0 1rem 0;
  font-weight: 500;
  color: #45464e;

  #scene {
    color: #8b8d97;
  }
  #name {
  }
}
