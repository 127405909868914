.use-case-main-wrapper {
  display: flex;
  height: 100%;
  border-radius: 0;
  column-gap: 32px;
  overflow: hidden;

  .next-btn-wrapper {
    display: flex;
    width: 100%;
  }
  .next-step-btn {
    height: 42px;
    border-radius: 6px;
    color: #ffffff;
    background: #2e2e2e;
    margin-right: 16px;
    width: 100%;
  }
  .customize-video-wrapper {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    aspect-ratio: 9 / 16;
  }
}

.steps-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 34px;
  width: 100%;
}
.ant-modal-content {
  background: #ffffff !important;
  padding: 16px !important;
  .ant-modal-close {
    margin: 0;
  }
}
.use-case-left-side {
  display: flex;
  justify-content: center;
  width: 50%;
  height: 100%;
  position: relative;
  border-radius: 12px;
}

.use-case-right-side {
  display: flex;
  flex-direction: column;
  width: 50%;
  overflow: hidden;
  justify-content: space-between;
  align-items: flex-start;
  background: #ffffff;

  .use-case-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
  .use-case-title {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 200px;
    h1 {
      color: #1b2b4b;
      margin: 0;
      font-family: Inter;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    p {
      margin: 0;
    }
  }
  .tag-list {
    gap: 8px;
    display: flex;
    align-items: center;
    height: 24px;

    width: 100%;
    overflow: auto hidden;

    &::-webkit-scrollbar-thumb {
      background: none !important;
    }
    &::-webkit-scrollbar {
      height: 6px !important;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: #babac0 !important;
      }
    }
  }
  .usecase-tag {
    height: 24px;
    padding: 0px 8px;
    border-radius: 20px;
    border: none;
    color: #4d5566;
    background-color: #f2f4f8;
    font-family: Inter;
    font-size: 10px;
    font-weight: 700;
    text-align: left;
    line-height: 24px;
    white-space: nowrap;
  }
  .about-template {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;

    h3 {
      color: #404959;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 700;
      line-height: 17px;
      text-align: left;
    }
  }
  .use-template-btn {
    width: 138px;
    height: 32px;
    padding: 0px 16px 0px 16px;
    gap: 10px;
    border-radius: 6px 0px 0px 0px;
    opacity: 0px;
    background: #262626;

    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
