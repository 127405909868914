.HorizontalDivider {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.8rem 0;

  .line {
    height: 1px;
    background-color: #a6a8b1;
    &.left {
      flex-grow: 0.2;
      flex-basis: 10%;
    }
    &.right {
      flex-grow: 1;
      flex-basis: 80%;
    }
  }

  .text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #a6a8b1;
  }
  .bin-icon {
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #a6a8b1;
    &:hover {
      color: #6d6c6d;
    }
  }
}
