// Make it scrollable and not overflow the window
.schema-view {
  overflow-y: auto;
  --blings_that_gray: transparent !important;
}

.schema-view,
.schema-card {
  background: none;

  .ant-input,
  .label-text,
  ._label-text-span,
  span {
    --ant-color-text-heading: #4d5566;
    color: var(--ant-color-text-heading);
  }

  ._header {
    margin-top: 0.5rem;
  }

  ._main-buttons {
    padding: 1rem 1rem;
    --blings_teal_60: #8c8c8c;

    ._main-button-options {
      color: var(--blings_teal_60);

      &:hover {
        color: var(--blings_pink);
      }
    }
  }

  ._add-options-textArea-card {
    .ant-form-item {
      width: 13rem;

      & > .ant-row {
        width: 100%;
      }

      .ant-form-item-row {
        width: 100%;
      }
    }
  }

  .ant-btn {
    span:not(.anticon.anticon-code) {
      color: white;
    }
  }

  .schema-card {
    & > .ant-card-body {
      padding: 0;
    }
  }

  .ant-input,
  .ant-input-number,
  .ant-select-selector,
  .ant-checkbox-inner {
    color: black;
    --ant-color-text: black;
    background: var(--ant-color-bg-container) !important;
    border: solid 1px var(--blings_input_gray) !important;

    &:focus {
      border-color: var(--blings_input_focus) !important;
    }

    box-shadow: none !important;

    &::placeholder {
      color: black;
      opacity: 0.5;
    }

    &::after {
      border-color: black;
    }
  }

  .ant-select-single {
    background-color: white;
  }

  ._object-field-group {
    &:hover {
      background: none !important;
    }

    .ant-collapse-content {
      background: none !important;
    }
  }

  .ant-btn {
    span:not(.anticon.anticon-code) {
      color: white;
    }
  }

  .schema-card {
    & > .ant-card-body {
      padding: 0;
    }
  }

  .ant-input,
  .ant-input-number,
  .ant-select-selector,
  .ant-checkbox-inner,
  .ant-input,
  .ant-input-number,
  .ant-select-selector,
  .ant-checkbox-inner .ant-input {
    color: black !important;
  }

  .ant-input-number,
  .ant-select-selector,
  .ant-checkbox-inner {
    color: black;
    --ant-color-text: black;
    background: var(--ant-color-bg-container) !important;
    border: solid 1px var(--blings_input_gray) !important;

    &:focus {
      border-color: var(--blings_input_focus) !important;
    }

    box-shadow: none !important;

    &::placeholder {
      color: black;
      opacity: 0.5;
    }

    &::after {
      border-color: black;
    }
  }

  .ant-collapse-content-box,
  .ant-select-single {
    background-color: white !important;
  }

  ._object-field-group {
    &:hover {
      background: none !important;
    }

    .ant-collapse-content {
      background: none;
    }
  }

  ._object-fieldTemplate {
    background: white;

    ._fieldTemplate {
      background: none;
    }

    ._footer {
      background-color: var(--blings_light_gray);
      padding: 0 15px 15px 15px;

      & > div:not([type="button"]) {
        display: flex;
        gap: 1rem;
      }
    }

    ._full-edit-field {
      background-color: var(--blings_light_gray);
      border-left: none;

      ._full-edit-field-content {
        padding: 0 29px;
      }
    }

    ._icon-btn {
      background-color: #a6a8b1d5;

      &:hover {
        background-color: #a6a8b1;
      }
    }

    ._base-field {
      background: white;
      padding: 0 1.5rem;

      ._bf-right {
        ._icon-btn {
          background-color: transparent;
        }
      }
    }

    .ant-btn-primary {
      color: white;

      &:not(:disabled) {
        background-color: var(--blings_pink);
      }

      &:is(:disabled) {
        background-color: none !important;
      }
    }

    ._media-card {
      background-color: var(--blings_light_gray);
      border: 1px solid #d9d9d9;
    }

    ._upload-button {
      .anticon {
        background-color: white;

        &:hover {
          color: var(--blings_pink);
        }
      }
    }
  }

  .JSONFormAntd-module_DynamicForm__-pQCX
    .JSONFormAntd-module_JSONForm__Y1CbB
    .ant-collapse {
    background-color: #fff !important;
  }

  ._object-fieldTemplate {
    background: white;

    ._fieldTemplate {
      background: none;
    }

    ._footer {
      background-color: var(--blings_light_gray);
      padding: 0 15px 15px 15px;

      & > div:not([type="button"]) {
        display: flex;
        gap: 1rem;
      }
    }

    ._full-edit-field {
      background-color: var(--blings_light_gray);
      border-left: none;

      ._full-edit-field-content {
        padding: 0 29px;
      }
    }

    ._icon-btn {
      background-color: #a6a8b1d5;

      &:hover {
        background-color: #a6a8b1;
      }
    }

    ._base-field {
      background: white;
      padding: 0 1.5rem;

      ._bf-right {
        ._icon-btn {
          background-color: transparent;
        }
      }
    }

    .ant-btn-primary {
      color: white;

      &:not(:disabled) {
        background-color: var(--blings_pink);
      }

      &:is(:disabled) {
        background-color: none !important;
      }
    }

    ._media-card {
      background-color: var(--blings_light_gray);
      border: 1px solid #d9d9d9;
    }

    ._upload-button {
      .anticon {
        background-color: white;

        &:hover {
          color: var(--blings_pink);
        }
      }
    }
  }

  .JSONFormAntd-module_DynamicForm__-pQCX
    .JSONFormAntd-module_JSONForm__Y1CbB
    .ant-collapse {
    background-color: #fff !important;
    padding: 10px 0;
  }

  ._fieldTemplate {
    background: none;
  }
}

.schema-view:hover {
  cursor: pointer;
}
