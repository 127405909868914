.Video,
.Image,
.Audio {
  max-width: 13rem;
  max-height: 9rem;
  object-fit: cover;
}
.MediaCard {
  flex-grow: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 5.5rem;
  width: 7rem;
  min-width: 7rem;
  background-color: var(--blings_that_gray);
  border: none;
  margin: 0px;
  margin-top: 0.3rem;
  padding: 0px;
}
.AudioCard {
  flex-grow: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 5.5rem;
  width: 7rem;
  min-width: 7rem;
  background-color: transparent;
  border: none;
  margin: 0px;
  padding: 0px;

  audio::-webkit-media-controls-play-button,
  audio::-webkit-media-controls-volume-slider {
    color: #333;
  }
  .Audio::-webkit-media-controls-timeline,
  .Audio::-webkit-media-controls-current-time-display,
  .Audio::-webkit-media-controls-time-remaining-display {
    display: none;
  }

  .Audio::-moz-range-track {
    display: none;
  }

  .Audio::--ms-track {
    display: none;
  }

  /* More media control styles can be added */

  .ant-card-body {
    padding: 24px 0;
    width: 100%;
  }
}
