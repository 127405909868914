.experiment-panel {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  overflow: auto;
  background-color: #fff;
  border-radius: 16px;

  // make a scrollable container if the content is too long
  .experiment-panel-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}
