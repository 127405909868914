.TextButton {
  cursor: pointer;
  &.underline {
    text-decoration: underline;
  }
  font-weight: 400;
  &:hover {
    color: var(--blings_pink);
  }
}
