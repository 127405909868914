.ai-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  gap: 1.5rem;
}

.ai-optimization-optimization-selection-card{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  min-width: 400px;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.ai-optimization-container {
  padding: 56px 51px !important;
  width: 100%;
  >.ant-card-body {
    padding: 0;
  }
}


.ai-optimization-not-allowed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.ai-optimization-not-allowed-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ai-optimization-not-allowed-content span {
  font-weight: 400;
  font-size: 14px;
  color: #bec0ca;
}