.ProjectSearch {
  height: 200px;
  overflow-y: scroll;
  cursor: pointer;
}
.ConnectToProject {
  .ant-select-focused {
    .ant-select-selection-item {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
