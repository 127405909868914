@import "../consts";

.edit-content-main {
  height: 100%;
  overflow: auto;
  padding: 0rem calc(2rem + 16px) 20px 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .SubHeader {
    font-size: 20px;
    color: #1b2b4b;
    font-weight: 600;
  }
  .sub-SubHeader {
    font-size: 14px;
    color: #1b2b4b;
    font-weight: 500;
  }

  .Container {
    height: 100%;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    overflow: hidden;
    .Scenes {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      max-width: 280px;
      background-color: white;
      overflow: hidden;
      border: solid 1px #cdd5e5;
      border-radius: 12px;
    }
    .Edit {
      border: solid 1px #cdd5e5;
      border-radius: 12px;
      overflow: hidden;
      height: 100%;
      width: 100%;
      max-width: 580px;
      background-color: white;
      .SubHeader {
        font-size: 20px;
        color: var(--black-60, #45464e);
        font-weight: 700;
      }
      .EditViewHeader {
        .SubHeader {
          font-size: 20px;
          color: var(--black-60, #45464e);
          font-weight: 700;
          margin: 0.8rem 0;
          overflow: hidden;
          white-space: nowrap;
        }
        display: flex;
        align-items: center;
        gap: 0.3rem;
        justify-content: space-between;
        .SearchHeader {
          width: 50%;
          .Search {
            width: 100%;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    .Demo {
      height: 100%;
      width: 100%;
      max-width: 1000px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }
}
