.SceneCard {
  width: 85%;
  scroll-margin: 1rem;
  cursor: pointer;
  margin: 12px 8px;
  height: 60px;
}

.scene-view {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .ant-menu-submenu {
    padding: 0 16px !important;

    .ant-menu-item {
      margin: auto 0;
      padding: 0 16px !important;
    }
  }
  .header-text {
    margin: 1.5rem 16px;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: left;
    font-weight: normal;
    color: var(--blings_card_title_gray);
  }
  .ant-menu {
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    --ant-menu-active-bar-border-width: 0;
    background: none;
    --ant-menu-sub-menu-item-bg: white;
    .ant-menu-submenu-title {
      .ant-menu-title-content {
        // color: white;
      }
    }
    .ant-menu-title-content {
      color: var(--blings_card_text_gray);
    }
  }
  .menu-after::after {
    transition: transform 0.02s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-inline .ant-menu-item-selected::after {
    transition: transform 0.02s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-root {
    border-radius: 9px;
  }
  .ant-menu-submenu-title {
    padding: 0 16px !important;
    width: 100%;
    border-radius: 9px;
    color: #1c1d22;
    margin: 6px 0 !important;
    &:hover {
      background-color: var(--blings_pages_background);
      color: #1c1d22;
    }
  }
  .selected-icon {
    color: transparent;
  }
  .ant-menu-sub {
    background-color: white;
    .ant-menu-item {
      border-radius: 9px;
      padding: 0 16px !important;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    .ant-menu-item-active {
      background-color: #ebebeb;
      color: #1c1d22;
    }
    .ant-menu-submenu-active .ant-menu-light {
      color: #1c1d22;
    }
    .ant-menu-item-selected {
      color: #1c1d22;
      background-color: var(--blings_primary);
      .selected-icon {
        color: white;
      }
      .ant-menu-title-content {
        color: white;
      }
      &::after {
        opacity: 0;
      }
    }
  }

  &.all-scenes {
    .ant-menu-submenu-title {
      background-color: var(--blings_primary) !important;
      color: white;
      .ant-menu-title-content {
        color: white;
      }
      // padding-left: 2rem !important;
      width: 100%;
      border-radius: 9px;
      margin: 2.5% 0;
      &:hover {
        // background-color: #ffe6e6;
      }
    }
    .ant-menu-item-selected {
      border-radius: 0;
      background-color: white;
      .ant-menu-title-content {
        color: black;
        font-weight: normal;
      }
      .selected-icon {
        color: var(--blings_primary);
      }
    }
  }
  .ant-menu {
    color: #1c1d22;
  }
  .AllScenes {
    text-align: center;
    text-decoration: underline;
    margin: 1rem 0;

    &.IsSelected {
      & > a {
        // color: var(--blings_pink);
      }
      color: white;
      text-decoration: none;
    }
  }
}
