.FrameIndicator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-width: 5rem;
  text-align: right;
  color: white;

  .FrameIndicatorInput {
    margin-right: 0.5rem;
    line-height: 1.5;
    border: solid 1px var(--blings_input_gray) !important;
    border-radius: var(--ant-border-radius) !important;
    padding: 4px 11px !important;
  }
  .FrameIndicatorMax {
    color: white;
  }
}
.frame-indicator-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1em;
  align-items: center;
}
