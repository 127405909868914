.JSONForm .MediaWidget .value-text a {
  color: inherit;
}

.OverviewPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.overview-content {
  display: flex;
  height: 100%;
  overflow: hidden;
  padding: 0 2rem;
  justify-content: flex-start;
  padding-bottom: 20px;

  .schema-view {
    // margin-bottom: 1rem;
    max-height: 65vh;
    min-height: 10rem;
    overflow: auto;
    & > div {
      height: 100%;
      & > form {
        border: solid 1px var(--blings_card_border);
        border-radius: 12px;
        overflow: hidden;
      }
    }
  }
  .edit-schema-external {
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    opacity: 0.6;
    margin-bottom: 1rem;
  }
  .player-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .left {
    width: 38rem;
  }
  .right {
    max-width: 60%;
    max-height: 100%;
    margin-top: 1rem;
  }
  .text {
    padding: 2rem;
  }
  .demo_iframe {
    padding: 4px;
    background: #4848485c;
    border: 4px solid var(--blings_primary_highlight);
    border-radius: 9px;
  }

  .JSONForm {
    max-height: 75vh;
  }
  .JSONForm > .form-group {
    max-height: 80vh;
    flex-wrap: nowrap;
  }
  // .JSONForm > .form-group > .ant-col {
  //   flex: 0 0 auto;
  // }
  //.JSONForm {
  //  .ant-form-item-label {
  //    font-weight: 500;}
  //  .field-array > .ant-form-item-control-wrapper {
  //    padding-left: 1rem;
  //  }
  //  .field-object-1 > .field {
  //    padding-left: 1rem;
  //
  //  }
  //  .field-boolean {
  //    display: flex;
  //    > .ant-form-item-label {
  //      margin-right: 1rem;
  //    }
  //  }
  //  //flex: 1;
  //}

  > .ant-row-flex {
    max-width: 100%;
  }

  .__Blings__player__container {
    z-index: 0;
  }
}

.overview-page-layout {
  background-color: #00000000;
  border-radius: 8;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 0 30px;
  gap: 20px;

  .content-layout {
    background-color: #00000000;
    padding: 0;
    gap: 30px;
    padding-bottom: 20px;
    height: 100%;
    max-height: 100%;

    .content {
      background-color: #00000000;
      display: flex;
      height: 100%;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      gap: 20px;

      .data {
        display: flex;
        flex-direction: column;
        align-content: center;
        width: 50%;
        min-width: 800px;
        gap: 20px;
      }

      .demo-sdk {
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: min(900px, 45vw);
        display: flex;
      }
    }
  }
}

.separator {
  margin: 0;
  padding: 0;
  height: 1px;
  background-color: #cdd5e5;
  width: 100%;
  margin-bottom: 20px;
}
