.ai-experiment-card-container {
}

.ai-experiment-container-header {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  gap: 16px;
  h3 {
    text-transform: capitalize;
    margin: 0;
    font-weight: 400;
    size: 14px;
    color: #6e7079;
  }
}

.ai-experiment-hr-separator {
  border: 1px solid #f5f5f5;
  width: 100%;
  margin: 0;
}
