.integration-page {
  overflow: hidden;
  height: 100%;
  .page-subtitle {
    font-size: 1rem;
    margin: 2rem 0 1rem 0;
    color: var(--blings_pink);
  }
  .form-item-scene {
    .ant-select-selection-overflow {
      margin-top: 1.5rem;
    }
  }
  .ant-select-selection-overflow {
    margin-left: 0.5rem;
  }
  .crm-select {
    margin-bottom: 1rem;
  }
  .sub-text-1 {
    margin-bottom: 1rem;
  }
  display: flex;
  flex-direction: row;

  .BlingsContainer {
  }
  #scenes-label {
    position: absolute;
    left: 13px;
    top: 6px;
    z-index: 1;
    color: var(--blings_blue);
    font-size: 10px;
  }

  .integration-tab-buttons {
    width: 180px;
    padding: 2rem;
    padding-bottom: 0;
    color: var(--blings_blue);
    .integration-tab-button {
      width: 100px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      cursor: pointer;

      .title {
        text-align: center;
        margin-top: 15px;
      }
    }

    .integration-tab-button:hover:not(.selected-integration-tab-button) {
      background-color: #ebecec;
    }

    .selected-integration-tab-button {
      color: var(--blings_pink);
      border-left: solid 4px var(--blings_pink);
      border-radius: 3px;

      .title {
        font-weight: 700;
      }
    }
  }

  .integration-tabs-content {
    padding: 2rem;
    overflow: auto;
    width: 100%;
  }

  #code2{
    // handle text overflow with line breaks
    white-space: pre-wrap;
  }
}
