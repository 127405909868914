.use-case-wrapper {
  display: flex;
  height: 100%;
  border-radius: 20px;
  column-gap: 16px;
}
.ant-modal-content {
  background: #ffffff !important;
}
.left-side {
  display: flex;
  justify-content: center;
  width: 426px;
  position: relative;
  background: #f2f4f8;
  border-radius: 12px;

  .video-content {
    position: relative;
    width: 306px;
    height: 621px;
    background: #f2f4f8;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    position: relative;
    height: 621px;
    width: 306px;
    background-size: cover;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 30px;
  }
}

.arrows-btn {
  position: absolute;
  justify-content: space-between;
  display: flex;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);

  .ant-btn {
    border-radius: 50% !important;
    background: none !important;
    color: #404959 !important;
    box-shadow: 0px 0px 12px 2px #00000026 !important;
    border: none !important;
  }
}

.right-side {
  display: flex;
  flex-direction: column;
  width: 426px;
  justify-content: space-between;
  align-items: flex-start;
  background: #ffffff;

  .static-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 100%;
  }

  p {
    color: #404959;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
  }
  .static-title {
    h1 {
      color: #404959;
      margin: 0;
      font-family: Inter;
      font-size: 12px;
      font-weight: 700;
      line-height: 19.54px;
      text-align: left;
      margin-bottom: 16px;
    }
    h2 {
      color: #404959;
      margin: 0;
      font-family: Inter;
      font-size: 12px;
      font-weight: 700;
      line-height: 19.54px;
      text-align: left;
    }
  }

  .static-text-content {
    margin-bottom: 16px;
  }

  .tag-list {
    gap: 8px;
    display: flex;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid #cdd5e5;

    width: 100%;
    overflow: auto hidden;

    &::-webkit-scrollbar-thumb {
      // background: none !important;
      background: #babac0 !important;
    }
    &::-webkit-scrollbar {
      height: 6px !important;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        // background: #babac0 !important;
      }
    }
  }
  .usecase-tag {
    height: 24px;
    padding: 0px 8px;
    border-radius: 20px;
    border: none;
    color: #4d5566;
    background-color: #f2f4f8;
    font-family: Inter;
    font-size: 10px;
    font-weight: 700;
    text-align: left;
    line-height: 24px;
    white-space: nowrap;
  }
  .static-about-template {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    margin-top: auto;

    h3 {
      color: #404959;
      font-family: "Inter";
      font-size: 12px;
      font-weight: 700;
      line-height: 17px;
      text-align: left;
      padding: 0px;
      margin: 0px;
    }
  }
  .use-template-btn {
    width: 138px;
    height: 32px;
    padding: 0px 16px 0px 16px;
    gap: 10px;
    border-radius: 6px 0px 0px 0px;
    opacity: 0px;
    background: #262626;

    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.static-separator {
  margin: 0px;
  padding: 0;
  height: 1px;
  background-color: #cdd5e5;
  width: 100%;
  margin-bottom: 20px;
}
