.project-settings {
  padding: 0 2rem;
  padding-bottom: 20px;
  overflow: hidden;
  overflow-x: auto;
  height: 100%;

  .tab-divider {
    border: none;
  }
  .BlingsBtn {
    font-weight: bold;
  }

  * {
    &::-webkit-scrollbar {
      width: 14px !important;
    }

    &::-webkit-scrollbar-thumb {
      border: solid 3px transparent !important;
      background-clip: padding-box !important;
    }
  }
  .ant-tabs .ant-tabs-nav-wrap {
    border-bottom: 1px solid #cdd5e5 !important;
    width: 100%;
    justify-content: flex-start;

    .ant-tabs-tab.ant-tabs-tab-active {
      width: 70px;
      justify-content: center;
    }
    .ant-tabs-tab {
      width: 70px;
      justify-content: center;
    }
  }
  .settings-tabs {
    width: 580px;
    height: 100%;
    border: solid 1px var(--blings_card_border);
    border-radius: 12px;
    background-color: white !important;
    display: flex;
    overflow-y: clip;
    overflow-x: unset;

    .ant-tabs-nav {
      width: 520px;
      margin-left: 2rem;
      height: 55px;
    }
    .ant-tabs .ant-tabs-content {
      position: none;
      overflow-y: hidden;
    }
  }

  .ant-card {
    height: 100%;
    border: none !important;
    background: none !important;
  }
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-tabpane {
    .ant-divider-horizontal {
      margin: 0 0 0.75rem 0;
    }
  }
}

.project-conetet {
  height: calc(100% - 20px);
  overflow: auto;
  width: 100%;
  padding-right: 10px !important;

  .connect-data-source {
    padding: 0 2rem;
    margin-top: 20px;
    .ant-card {
      .ant-card-body {
        padding-left: 0;
      }
      .ant-card-head {
        padding: 0;
      }
    }
    .set-button {
      font-weight: bold;
      &:disabled {
        background-color: #cdd5e5;
        color: white;
      }
    }
  }
}
