.BlingsContainer {
  background-color: #ffe8ee;
  border-radius: 6px;
  border-left: 4px solid #ff3666;
  padding: 12px 32px 18px 32px;
  .link-container {
    background-color: white;
    border: 1px solid #cccccc;
    border-radius: 3px;
    max-height: 100px;
    scroll-behavior: auto;
    overflow-y: auto;
    display: flex;
    margin: 0.2rem 0 0.5rem 0;

    .link {
      max-width: 100%;
      display: block;
      padding: 10px 20px;
      font-family: "Monda", sans-serif;

      .token {
        color: var(--blings_primary);
        font-weight: 700;
      }
    }
  }
  .copy-extra-information {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
