@import "./consts";
html,
body,
#root,
body {
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5715;
  font-feature-settings: "tnum", "tnum";
}

p {
  margin-top: 0px;
}

.Search-Input {
  --ant-input-active-bg: white !important; // issues with dynamic form conflict antd theme
  --ant-input-hover-bg: white !important;
  --ant-color-text-placeholder: rgba(0, 0, 0, 0.5) !important;
}
._desc-input {
  background-color: var(--blings_light_gray) !important;
  color: black !important;
}
._df-tooltip {
  .ant-tooltip-inner {
    background: white;
    color: var(--blings_card_text_gray);
    .ant-input {
      &:hover {
        border-color: var(--blings_pink);
      }
      &:focus-within {
        border-color: var(--blings_pink);
      }
    }
  }
}
#root {
  ._blings-dynamic-form {
    & > ._fieldTemplate {
      background: none;
    }
  }
  input::placeholder,
  textarea::placeholder {
    color: var(--blings_input_placeholder) !important;
  }

  .ant-select-selection-placeholder {
    color: var(--blings_input_placeholder) !important;
  }
  .ant-form .ant-form-item-label label,
  .ant-space-item textarea {
    color: var(--blings_card_text_gray) !important;
  }

  .ant-card-head-wrapper .ant-card-head-title {
    color: var(--blings_card_title_gray) !important;
  }

  .css-var-.ant-picker-css-var {
    &:hover {
      border: solid 1px var(--blings_input_focus) !important;
    }
  }

  .css-var-.ant-table-css-var {
    --ant-table-border-color: #f2f4f8 !important;
  }

  .ant-select-selector,
  .ant-picker-outlined {
    background-color: white !important;
    border: solid 1px var(--blings_input_gray) !important;
    border-radius: var(--ant-border-radius) !important;
    &:hover {
      border: solid 1px var(--blings_input_focus) !important;
    }
  }
  .ant-select-focused .ant-select-selector,
  .ant-picker-focused {
    border: solid 1px var(--blings_input_focus) !important;
    border-radius: var(--ant-border-radius) !important;
  }

  .ant-form-item-control,
  .ant-input-number {
    .ant-input {
      border: solid 1px var(--blings_input_gray) !important;
      border-radius: var(--ant-border-radius) !important;

      &:focus {
        border: solid 1px var(--blings_input_focus) !important;
      }
    }
  }

  .schema-view,
  .schema-card {
    .ant-input,
    .label-text,
    ._label-text-span,
    span {
      --ant-color-text-heading: #4d5566;
      color: var(--ant-color-text-heading);
    }
    ._header {
      margin-top: 0.5rem;
    }
    textarea {
      background-color: white !important;
      color: black !important;
    }
    ._main-buttons {
      padding: 1rem 1rem;
      --blings_teal_60: #8c8c8c;
      ._main-button-options {
        color: var(--blings_teal_60);
        &:hover {
          color: var(--blings_pink);
        }
      }
    }

    ._add-options-textArea-card {
      .ant-form-item {
        width: 13rem;
        & > .ant-row {
          width: 100%;
        }
        .ant-form-item-row {
          width: 100%;
        }
      }
    }
    .ant-btn {
      span:not(.anticon.anticon-code) {
        color: white;
      }
    }
    .schema-card {
      & > .ant-card-body {
        padding: 0;
      }
    }
    .ant-input,
    .ant-input-number,
    .ant-select-selector,
    .ant-checkbox-inner {
      color: black;
      --ant-color-text: black;
      background: var(--ant-color-bg-container) !important;
      border: solid 1px var(--blings_input_gray) !important;

      &:focus {
        border-color: var(--blings_input_focus) !important;
      }
      box-shadow: none !important;
      &::placeholder {
        color: black;
        opacity: 0.5;
      }
      &::after {
        border-color: black;
      }
    }
    .ant-select-single {
      background-color: white;
    }
    ._object-field-group {
      &:hover {
        background: none !important;
      }
      .ant-collapse-content {
        background: none;
      }
    }
    ._object-fieldTemplate {
      background: white;
      ._fieldTemplate {
        background: none;
      }
      ._footer {
        background-color: var(--blings_light_gray);
        padding: 0 15px 15px 15px;
        & > div:not([type="button"]) {
          display: flex;
          gap: 1rem;
        }
      }
      ._full-edit-field {
        background-color: var(--blings_light_gray);
        border-left: none;
        ._full-edit-field-content {
          padding: 0 29px;
        }
      }

      ._icon-btn {
        background-color: transparent;
      }
      ._base-field {
        background: white;
        padding: 0 1.5rem;
        ._bf-right {
          ._icon-btn {
            background-color: transparent;
          }
        }
      }
      .ant-btn-primary {
        color: white;
        &:not(:disabled) {
          background-color: var(--blings_pink);
        }
        &:is(:disabled) {
          background-color: none !important;
        }
      }
      ._media-card {
        background-color: var(--blings_light_gray);
        border: 1px solid #d9d9d9;
      }
      ._upload-button {
        .anticon {
          background-color: white;
          &:hover {
            color: var(--blings_pink);
          }
        }
      }
    }
    //     --blings_teal: transparent;
    //     --blings_that_gray: transparent;
    //     .ant-card-bordered {
    //       .ant-select-selector {
    //         background-color:white !important;
    //         border:1px solid #d9d9d9 !important;
    //       }
    //     }
    //     span {
    //       color: #45464e;
    //     }
    //     .ant-select-selector {
    //       border: #d9d9d9 1px solid;
    //     }
    //     div:not(.ant-card-extra) {
    //       button {
    //         span {
    //           color: white;
    //         }
    //       }
    //     }
    //     .ant-collapse {
    //       // border:1px solid black;
    //       background-color: transparent;
    //     }
    //     .ant-input {
    //       color: #000000a6;
    //       background-color: white;
    //       border: #d9d9d9 1px solid;
    //       border-radius: 6px;
    //     }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: black;
  opacity: 0.5;
}
.ant-radio-input {
  background-color: blue;
}
.css-var-.ant-popconfirm {
  z-index: 1300;
}

.ant-switch.css-var-.ant-switch-disabled {
  background-color: #adadad !important;
}

.ant-switch.css-var-.ant-switch-unchecked {
  background-color: #7f8899 !important;
}
.ant-switch.css-var-.ant-switch-checked {
  background-color: var(--blings_primary) !important;
}

.App {
  height: 100%;
  overflow: auto;
}
a {
  color: #45464e;
  &:hover {
    color: var(--blings_pink);
  }
}

::selection {
  background: #ff3666; /* Background color of the selection */
  color: white; /* Text color of the selection */
}

* {
  &::-webkit-scrollbar {
    width: 14px !important;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    border: solid 3px transparent !important;
    background-clip: padding-box !important;
    background-color: #babac0 !important;
    border-radius: 16px !important;
    // border: 4px solid var(--blings_pages_background) !important;
    min-height: 35px;
  }

  &::-webkit-scrollbar-button {
    display: none !important;
  }
}
.project-tab-padding {
  // margin: 2rem;
}

.ant-btn {
  padding: 0.4rem 1.2rem;
  line-height: 1;
  border-radius: 6px !important;
  height: inherit;
  color: #fff;
  background-color: var(--blings_pink);
  box-shadow: none !important;

  &.ant-btn-primary {
    font-weight: bold;
    border: transparent;
  }
  &.ant-btn-primary:not([disabled]):hover {
    background-color: var(--blings_btn_hover);
  }
  &.ant-btn-primary:is([disabled]) {
    background-color: var(--blings_disabled_btn) !important;
  }
  &.ant-btn-circle {
    padding: 0.3rem;
  }
  &.text-btn {
    background: none !important;
    color: var(--blings_pink) !important;
    box-shadow: none !important;
    border: solid 1px none !important;
    font-weight: bold;
    &:hover {
      border: solid 1px var(--blings_btn_hover) !important;
    }
  }
  &.outline-cancel-btn {
    background: none !important;
    color: var(--blings_card_text_gray) !important;
    box-shadow: none !important;
    border: solid 1px #cdd5e5 !important;
    &:hover {
      border: solid 1px var(--blings_input_focus) !important;
      color: #ff3666 !important;
    }
  }
  &.icon {
    font-weight: bold;
    border-radius: 6px !important;
    color: var(--blings_icon_gray) !important;
    background: none;
    &:hover {
      color: var(--blings_pink) !important;
    }
    .anticon.anticon-code {
      &:hover {
        color: var(--blings_pink) !important;
      }
    }
  }
}
.thumb-upload {
  .ant-upload {
    margin: 0;
  }
}
.ant-upload.ant-upload-select-picture-card {
  width: 7rem;
  height: 7rem;
  background-color: transparent;
  border: none;
}
.ant-switch {
  margin-right: 0.5rem;
  margin-left: 0.5rem;

  &:not([checked]) {
    background-color: #cdd5e5 !important;
  }
}

.ant-layout {
  & > div {
    padding: 1rem;
  }
}
.ant-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.ant-popover-title {
  font-size: 11px;
}
.view-live-version-popover {
  .ant-popover-title {
    font-size: 14px;
  }
}
.ant-col:last-child {
  padding-right: 0 !important;
}

.SelectScene {
  .ant-select-selection-overflow {
    margin-right: 1.5rem;
  }
}

// outside of .App since antd <Select> renders outside
.fontW900 {
  font-weight: 900;
}
.fontW700 {
  font-weight: 700;
}
.fontW600 {
  font-weight: 600;
}

.ant-card {
  height: 100%;
  border: solid 1px var(--blings_card_border);
  border-radius: 12px;
  background-color: white !important;
}

.ant-btn {
  height: 32px;
}

.Main {
  .page-layout-padding {
    padding: $layoutpaddingsides;
    height: 100%;
    overflow: auto;
  }
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.button-submit {
  margin-bottom: 4rem;
}
.spinner {
  text-align: center;
  margin-top: calc(45vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .Rotate {
    margin-right: 0.5rem;
    height: 18px;
    width: 18px;
    animation: rotation 2s linear infinite;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
