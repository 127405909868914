.player-wrapper {
  height: 100%;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  align-items: flex-start;
}

.player-container {
  border-radius: 12px;
  background: #232323;
  padding: 16px;
  overflow: auto;
  overflow-y: hidden;
  max-width: fit-content;
  resize: horizontal;
  min-width: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .player {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-height: calc(100% - 47px);
    overflow: hidden;
    border-radius: 6px;
  }

  .command-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 0 2px;
    min-width: 235px;
    width: 100%;

    .action-btn {
      display: flex;
      gap: 10px;
      justify-content: space-between;
    }
    .FrameIndicatorInput {
      width: 70px !important;
      padding: 4px !important;
    }
  }
}

.empty-player {
  padding: 16px;
  border-radius: 12px;
  aspect-ratio: auto 9 / 16;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid #cdd5e5;
  font-size: 14px;
  height: 100%;
  max-width: 100%;
}
.loading-placeholder {
  aspect-ratio: auto 9/16;
  flex: 1 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  align-items: center;
}

.player-spinner {
  display: flex;
  position: absolute;
  top: 50%;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
