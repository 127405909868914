.analytics {
  --analytics_view_width: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  overflow: hidden;

  h1,
  h2 {
    color: var(--blings_teal);
  }

  .title {
    display: flex;
    flex-direction: row;
    height: 2rem;
    justify-content: flex-start;
    margin-bottom: 1rem;

    h1 {
      margin-right: 1rem;
    }
  }

  .view-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 2rem;
    justify-content: space-between;
    margin-bottom: 1rem;

    h2 {
      margin-right: 1rem;

      span {
        font-weight: bold;
      }
    }
  }

  .views-container {
    overflow: auto;
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    gap: 2em 2em;
    padding: 0 2rem;
    padding-top: 10px;

    .analytics-view {
      min-width: calc(calc(100vw - var(--analytics_view_width)) / 3);
      max-width: calc(calc(100vw - var(--analytics_view_width)) / 3);
      background-color: white;
      border: solid 1px var(--blings_card_border);
      border-radius: 12px;

      padding: 10px;
      overflow: hidden;
      // Media query for large screens
      @media (min-width: 1268px) {
        &.funnel,
        &.watch-time {
          flex: 1 1 60%;
          width: auto; // Override the default width
          max-width: calc(100vw - var(--analytics_view_width));
        }
      }

      // Media query for smaller screens
      @media (max-width: 1267px) {
        &.funnel,
        &.watch-time {
          width: 100%; // First child takes full width
          max-width: 100%;
        }
      }
    }

    .first-row {
      // min-width: 83vw;
      width: 100%;
      display: flex;
      gap: 2rem;
    }

    .funnel {
      overflow: inherit;
    }
    .loading-time {
      height: 350px;
    }

    .top-cta {
      .content {
        width: "100%";
        height: "350px";
        background-color: var(--blings_background3);
        padding: 1rem;

        .out-of-group {
          display: flex;
          flex-direction: column;
          align-items: center;

          .percentage {
            color: var(--blings_pink);
            font-size: 2rem;
            font-weight: 700;
          }

          .out-of {
            font-weight: 700;
          }
        }

        thead {
          background-color: var(--blings_teal_80);
          color: var(--blings_teal_80);

          th {
            background-color: var(--blings_teal_80);
            color: white;
          }
        }

        .ant-table-cell-scrollbar:not([rowspan]) {
          box-shadow: none;
        }
      }
    }

    .dynamic-thumbnail {
      min-height: 350px;

      .count {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          margin: 0;
          font-weight: 600;
        }
      }
    }

    .loading-time {
      height: 418px;
      .content {
        position: relative;
        display: flex;
        flex-direction: column;

        .gauge {
          display: flex;
          justify-content: center;
          border: none;
        }
        .container {
          position: relative;

          .text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .alert {
          font-size: 0.75rem;
        }
      }
    }

    .sessions {
      //second row divide by three
    }

    .users {
    }

    .watch-time {
      height: 400px;
    }
    .per-device {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      background-color: inherit;
      min-width: calc(calc(100vw - var(--analytics_view_width)) / 4);
    }
    .bottom-row {
      max-width: calc(100vw - var(--analytics_view_width));
      display: flex;
      // height: 700px;
      flex-wrap: wrap;
      gap: 2rem;
    }
    .sessions-per-device {
      border: solid 1px var(--blings_card_border);
      border-radius: 12px;
      background-color: white !important;
      padding: 10px;
      // max-width: 15vw;
    }

    .sessions-os {
      border: solid 1px var(--blings_card_border);
      border-radius: 12px;
      background-color: white !important;
      padding: 10px;
    }

    .map {
      flex: 1 1 40%;
      width: auto; // Override the default width
      border: solid 1px var(--blings_card_border);
      border-radius: 12px;
      background-color: white !important;
      padding: 10px;
      min-width: calc(calc(100vw - var(--analytics_view_width)) / 2);
      // max-width: calc(calc(100vw - var(--analytics_view_width)) / 2);
      .country-view {
        display: flex;
        gap: 50px;

        .tableView {
          min-width: 30px;
        }

        .mapView {
          min-width: 400px;
          flex: 1 1 60%;
          min-width: 60%;
          width: auto;
        }
      }
    }

    table {
      tbody {
        tr:hover > td {
          background-color: rgba(var(--blings_pink), 0.4);
        }

        tr > td {
          border-color: #a9b6c34d;
          //font-family:"Open Sans"
        }
      }
    }
  }

  .tooltip {
    background-color: var(--blings_teal);
    color: "white";
    padding: ".5rem";
    border-radius: "5px";
    position: "relative";

    .color-block {
      width: 10px;
      height: 10px;
    }
  }

  .tooltip::after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    transform: translate(100%, 50%) rotate(0.25turn);
    background-color: var(--blings_teal);
  }
}
