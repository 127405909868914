.ai-optimization-panel-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 16px;
  padding: 50px;

  p {
    color: #a6a8b1;
    font-size: 12px;
    line-height: 20px;
  }
}

.ai-optimization-panel-header {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 24px;
  h3 {
    font-weight: 500;
    margin: 0;
    size: 14px;
    text-align: left;
  }

  p {
    margin-bottom: 0;
  }

  span {
    text-align: left;
    font-weight: 800;
  }
}

.experiment-panel-update-goal-button {
  padding: 8px 16px;
  background-color: #ffe6e7;
  border: 1px dashed var(--blings_pink);
  border-radius: 8px;
  color: #6e7079;
  transition: 0.25s;
  &:hover {
    transition: 0.25s;
    background-color: var(--blings_pink);
    color: #fff;
    cursor: pointer;
  }
}
