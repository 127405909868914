.LandingPageIntegration {
  min-width: 662px;
  max-width: 680px;
  .ant-icon-question-circle {
    font-size: 0.7rem;
  }

  display: flex;
  flex-direction: column;
  // margin: 2rem 0;

  .body {
    .BlingsContainer {
      width: 100%;
    }
    display: flex;
    flex-direction: row;
    width: 100%;

    .content {
      display: flex;
      flex-direction: column;

      .form {
        .ant-form-item {
          width: 320px;
        }

        flex-direction: column;

        #form-subtitle-extra {
          color: var(--blings_purple);
          font-size: 12px;
          margin-bottom: 0.2rem;
        }

        .ant-form-item-control-input-content {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .code-editor-container {
          display: flex;
          flex-direction: column;
          background-color: white;
          border: 1px solid #d9d9d9;
          width: 100%;
          padding: 7px 7px 2px 13px;

          label {
            font-size: 10px;
            color: var(--blings_blue);
            margin-bottom: 0.2rem;
          }
        }
      }

      .footer {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        gap: 1rem;
        justify-content: flex-end;
      }
    }
  }

  .video-container {
    margin: auto;
    padding: 0 5rem;

    video {
      width: 100%;
    }
  }

  .extra-info-popover {
    font-size: 11px;
  }

  .tab-container {
    position: relative;

    .svg {
      color: var(--blings_pink);
    }

    .content {
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 29%;
      align-items: center;
      padding-left: 8%;

      .icon {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }

      .title {
        font-size: 18px;
        color: white;
      }
    }
  }
}

li {
  list-style: circle;
}

.tooltip-integration {
  max-width: 150px;
  font-size: 14px;
  margin-left: 8px;
  margin-bottom: 1rem;
}
