.blings-dynamic-data-card {
  height: 100% !important;
  max-height: 100% !important;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-right: 16px !important;
  padding-left: 16px !important;

  .blings-card-title {
    min-height: 0 !important;
    height: 0 !important;
    padding-top: 16px !important;
    display: block;
    margin-bottom: 36px !important;
    font-size: 16px !important;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100% !important;
    padding-bottom: 16px !important;

    .schema-view {
      overflow-y: auto;
      width: 100%;
      height: 100%;

      ._header {
        display: none;
      }
    }

    .blings-dynamic-data-preview-container {
      display: flex;
      max-height: 100% !important;
      overflow-y: auto;
      height: 100%;

      .blings-dynamic-data-preview-selector {
        width: 50%;
        height: 100%;
        min-width: 280px !important;
        display: flex;
        gap: 22px;
        max-height: 100% !important;
        flex-direction: column;
        justify-content: space-between;
        overflow: auto;
        padding-bottom: 50px;

        border-right: 1px solid var(--blings_card_border);

        .blings-dynamic-data-preview-title {
          font-weight: 700;
          size: 14px;
          color: #4d5566;
          padding-left: 15px;
        }

        .blings-dynamic-data-preview-radio {
          display: flex;
          max-height: 100% !important;
          margin-right: 12px;
          width: 90%;
          overflow-y: auto;
          flex-direction: column;
          gap: 10px;
          padding: 1px 0;
          overflow-x: hidden;

          .blings-dynamic-data {
            color: var(--blings_card_text_gray);
            border: 0 !important;
            height: 42px;
            min-width: 180px !important;

            &:hover {
              color: var(--blings_btn_hover);
            }

            .data-content {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;

              display: flex;
              align-items: center;
              justify-content: space-between;

              ::selection {
                background-color: #ff3666;
              }

              &:hover {
                color: #e3355f;
              }
            }

            .data-point-name {
              white-space: nowrap;
              // overflow: hidden;
              // text-overflow: ellipsis;
              max-width: 150px;
              margin: 0;
              padding: 0;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }

        .selected {
          color: #e3355f !important;
          font-weight: 700;
          background-color: #ffeef2;
          border: none;

          ::selection {
            background-color: #ffeef2;
          }
        }
      }
      .ant-collapse {
        gap: 12px;
      }
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  .data-point-container {
    display: flex;
    align-items: center;
    gap: 0.4rem;

    .data-point-preview-text {
      font-weight: 600;
      color: var(--blings_card_title_gray);
      line-height: 22px;
    }

    .data-point-preview-text-p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: var(--blings_card_title_gray);
    }

    .data-point-select {
      width: 100% !important;
    }
  }

  .dp-field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .blings-card-dynamic-data-button {
    font-weight: 700;
    width: fit-content;
    height: 32px !important;
    min-height: 32px !important;
  }

  .loading-data {
    margin-left: 12px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 12px;

    p {
      font-weight: 300;
    }
  }
}

.ant-dropdown-menu-item-active {
  &:hover {
    background-color: #ffeef2 !important;
    color: #e3355f !important;

    svg {
      stroke: #e3355f;
    }
  }
}

.button-create-new-version {
  all: unset;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #cdd5e5;
  padding: 0.5rem 1rem;
  cursor: pointer;
  background-color: transparent;
  min-width: 200px;
  border-radius: 6px;

  span {
    font-weight: 700;
    color: #7f8899;
  }

  &:hover {
    color: #e3355f !important;
    border-color: #ffccd8;
  }
}

.div-button-create-new-version {
  border-radius: 6px;
  position: fixed;
  bottom: 35px;
  background-color: #ffffff;
  z-index: 999;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -30px;
    bottom: -10px;
    border-radius: 10px;
    background-color: #ffffff;
    z-index: -1;
    box-shadow: 40px 8px 32px rgba(0, 0, 0, 0);
  }
  &:hover {
    border-color: #ffccd8;

    svg {
      stroke: #e3355f;
    }

    .button-create-new-version span {
      color: #e3355f;
    }
  }
}

.dropdown-items {
  display: flex;
  align-items: center;
  gap: 4px;
}

.ant-input {
  border: 1px solid #ffccd8;
}

.ant-popconfirm-title {
  margin-left: 8px;
}

.ant-radio-button-wrapper {
  border-radius: 6px !important;
}

.ant-radio-button-wrapper::before {
  width: 0px !important;
}

.ant-radio-button-wrapper {
  outline-color: transparent !important;
}

.custom-ok-button {
  color: #7f8899 !important;
  &:hover {
    color: #e3355f !important;
    border-color: #e3355f !important;
  }
}

.custom-cancel-button {
  font-weight: 700;
}
