.JSONForm .MediaWidget .value-text a {
  color: inherit;
}

.edit-video-empty-state {
  border-radius: 12px;
  border: solid 1px #cdd5e5;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: calc((9 / 16) * 100vw);
  padding: 0 1rem;
  max-height: calc(100vh - 270px);
  aspect-ratio: auto 9/16;
  .text {
    text-align: center;
    font-weight: bold;
  }
}
.edit-video-DemoPage {
  height: 100%;
  width: 100%;
  display: flex;

  .demo_iframe {
    padding: 4px;
    background: #4848485c;
    border: 4px solid var(--blings_primary_highlight);
    border-radius: 9px;
  }

  .JSONForm {
    max-height: 75vh;
  }
  .JSONForm > .form-group {
    max-height: 80vh;
    flex-wrap: nowrap;
  }
  .JSONForm > .form-group > .ant-col {
    flex: 0 0 auto;
  }
  > .ant-row-flex {
    max-width: 100%;
  }

  .__Blings__player__container {
    z-index: 0;
  }

  .Horizontal {
    text-align: center;
    max-width: 100% !important;
  }

  .Vertical {
    max-width: unset !important;
  }
  .placeholder {
    max-height: calc(100vh - 240px);
    aspect-ratio: auto 9 / 16;
    background: white;
    border: solid 1px #cdd5e5;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
}
