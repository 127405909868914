.blings-modal-dynamic-data {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  p {
    margin: 0;
    padding: 0;
  }

  .blings-modal-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    margin-top: -9px;
    padding: 8px 0 16px 0;

    .scene-selector {
      display: flex;
      gap: 10px;
      align-items: center;
      .view-live-version-select {
        width: 300px !important;
      }
      .ant-select-selector {
        background-color: white !important;
        border: solid 1px var(--blings_input_gray) !important;
        border-radius: var(--ant-border-radius) !important;

        &:focus,
        &:hover,
        &:active {
          border-color: var(--blings_input_focus) !important;
        }

        .ant-select-selection-item {
          background-color: var(--blings_pages_background);
        }
      }
    }
  }

  .blings-modal-title {
    font-family: "Inter";
    font-weight: 700;
    font-size: 16px;
    line-height: 31.43px;
    margin-bottom: 3px;
    color: var(--blings_title_and_subtitle);
  }

  .blings-modal-subtitle {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--blings_title_and_subtitle);
  }

  .ant-modal-content {
    width: calc(500px + calc(calc(100vh - 70px) * 0.5625));
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      height: 100% !important;
      overflow-y: auto;
      width: 100%;
    }
  }

  .content {
    background: #f2f4f8;
    display: flex;
    max-height: 100% !important;
    height: 100%;
    overflow-y: hidden;
    max-width: 100%;
    column-gap: 10px;

    .data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: auto;
      width: 100%;
      aspect-ratio: 9 / 16;

      .blings-card-dynamic-data {
        display: flex;
        flex-direction: column;
        height: 100% !important;
        overflow: auto;
        padding-right: 16px !important;
        padding-left: 16px !important;
        > .ant-card-body {
          display: flex;
          flex-direction: column;
          height: 100%;
          max-height: 100% !important;
          overflow: auto;
          padding-bottom: 16px !important;

          .data-point-container {
            padding: 14px 0;
            display: flex;
            align-items: center;
            gap: 0.4rem;

            .data-point-preview-text {
              font-weight: 600;
            }

            .data-point-select {
              width: 100% !important;

              .ant-select-selector {
                background-color: white !important;
                border: solid 1px var(--blings_input_gray) !important;
                border-radius: var(--ant-border-radius) !important;

                &:focus,
                &:hover,
                &:active {
                  border-color: var(--blings_input_focus) !important;
                }
              }
            }
          }

          .blings-card-title {
            font-weight: 600;
            font-size: 14px;
            height: 32px;
            color: var(--blings_card_title_gray);
            padding: 6px 0;
          }

          .schema-view {
            display: flex;
            border: solid 1px var(--blings_input_gray);
            border-left: 0;
            border-right: 0;
            margin-bottom: 16px;
            height: 100%;
            overflow-y: auto;
          }

          .dp-view {
            width: inherit;

            .dp-field {
              width: 100%;
              display: flex;
              align-items: center;
              gap: 10px;

              .dp-field-end {
                display: flex;
                width: 100%;
                justify-content: flex-end;
                gap: 10px;
              }

              .dp-input {
                margin-left: 0 !important;
                background: var(--ant-color-bg-container) !important;
                border: solid 1px var(--blings_input_gray) !important;
                border-radius: var(--ant-border-radius) !important;

                &:focus {
                  border-color: var(--blings_input_focus) !important;
                }

                &:hover {
                  border-color: var(--blings_input_focus) !important;
                }

                &::placeholder {
                  color: #7f8899;
                  opacity: 0.5;
                }

                margin: 0 0.5rem;
                --ant-input-active-bg: white;
                --ant-input-hover-bg: white;
                --ant-color-text-placeholder: rgba(0, 0, 0, 0.5);
              }

              .dp-submit {
                padding-left: 16px;
                padding-right: 16px;
                color: #ffffff;
                font-weight: 700;
              }

              .pre-input {
                text-wrap: nowrap;
                font-weight: 700;
              }
            }

            .ant-btn {
              &:hover {
                border-color: var(--blings_input_focus) !important;

                p {
                  color: #ff3666 !important;
                }
              }
            }
          }
        }
      }

      .blings-card-scene-selector {
        height: 175px;
      }
    }

    .demo-sdk {
      width: min-content;
      overflow: hidden;
      border-radius: 12px;
      display: flex;
      max-width: 50%;
      min-width: 50%;
      height: auto;
      max-height: 100%;

      @media screen and (max-width: 1920px) {
        min-width: 50%;
      }
      @media screen and (max-width: 1440px) {
        min-width: 47.3%;
      }
      @media screen and (max-width: 1280px) {
        min-width: 44.3%;
      }

      justify-content: flex-start;
      .player-wrapper {
        border-radius: 6px;
        overflow: hidden;
        align-items: flex-end;
        width: fit-content !important;
        .empty-player {
          width: 100%;
        }
      }
    }
  }

  ._base-field {
    padding-left: 0 !important;
  }

  ._object-fieldTemplate {
    padding-left: 0 !important;
  }

  .JSONFormAntd-module_DynamicForm__-pQCX
    .JSONFormAntd-module_JSONForm__Y1CbB
    .ant-collapse
    .ant-collapse-header {
    padding-left: 0 !important;
  }
  .JSONFormAntd-module_DynamicForm__-pQCX
    .JSONFormAntd-module_JSONForm__Y1CbB
    .ant-collapse {
    gap: 12px;
  }
}

.opaque-button {
  background-color: #ffffff !important;
  color: #7f8899 !important;

  &:hover {
    color: #e3355f !important;
    border-color: var(--blings_input_focus) !important;
  }
}

.dp-view .ant-btn {
  border-color: #cdd5e5 !important;
}

.watching-title {
  font-family: "Inter";
  font-weight: 600;
  size: 12px;
  line-height: 22px;
}

.scene-selector-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.ant-modal-close {
  margin-top: 8px;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
