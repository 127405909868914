.blings-card-description {
  max-height: 370px;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;

  .blings-card-title {
    font-size: 16px !important;
    height: 0 !important;
    min-height: 0 !important;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .ant-card-body {
    padding-bottom: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .edit-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  height: auto !important;

  .edit-button {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    display: flex;
    cursor: pointer;
    gap: 0.5rem;
    justify-content: center;
    align-items: stretch;

    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }
  }

  .ant-input,
  .ant-input-number,
  .ant-select-selector,
  .ant-checkbox-inner {
    color: black;
    --ant-color-text: black;
    background: var(--ant-color-bg-container) !important;
    border: solid 1px var(--blings_input_gray) !important;

    &:focus {
      border-color: var(--blings_input_focus) !important;
    }

    box-shadow: none !important;

    &::placeholder {
      color: black;
      opacity: 0.5;
    }

    &::after {
      border-color: black;
    }
  }

  .description {
    max-height: 90px;
    overflow-y: auto;
    white-space: pre-line;
    color: var(--blings_card_text_gray);
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;

    .cancel-button {
      cursor: pointer;
      background-color: white !important;
      color: var(--blings_card_text_gray);
      border: 1px solid var(--blings_card_border);
      padding: 0.4rem 1.2rem;
      border-radius: 6px;
      line-height: normal;

      &:hover {
        border-color: var(--blings_input_focus);

        p {
          color: #ff3666;
        }
      }
    }

    .save-button {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      gap: 0.5rem;
    }
  }
}
