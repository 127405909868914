.SiteIntegration {
  .page-title {
    color: var(--blings_teal_60);
    font-size: 1.8rem;
    font-weight: 700;
  }

  .tab-buttons {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    .tab-button {
      width: 223px;
      min-width: 223px;
      height: 82px;
      border-radius: 4px;
      margin: 0 5px 0 16px;
      padding: 10px 21px;
      cursor: pointer;

      .title {
        color: var(--that_gray);
        font-size: 12px;
        font-weight: 700;
      }

      .subtitle {
        color: var(--that_gray);
        font-size: 12px;
        line-height: 14px;
      }
    }
    .disabled {
      color: lightgrey;
    }

    .tab-button.selected-tab-button {
      border: solid 1px var(--blings_pink);
      background-color: #ffe8ee;
    }

    .tab-button:hover:not(.selected-tab-button) {
      background-color: #ebecec;
      border: none;
    }
  }

  .tab-divider {
    padding: 0;
    margin: 0;
  }
}
