.variant-card-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 40px 0;
  gap: 20px;
}

.variant-card-asset-container {
  width: 60px;
  height: 60px;
  border-radius: 15%;
  overflow: hidden;
  display: inline-block; /* to align images inline */
  margin: 5px; /* optional, for spacing between images */
}

.variant-card-asset-exibition {
  width: 60px;
  height: 100%;
  object-fit: cover; /* ensures the image covers the container while maintaining aspect ratio */
  border-radius: 15%; /* rounds the corners of the image */
  img {
    background-image: url("https://via.placeholder.com/60"); /* placeholder image */
  }
}

.variant-card-exibition-name {
  width: 300px;
  font-size: 14px;
  color: #6e7079;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
  margin: 0;
}

.variant-card-selection-count {
  font-size: 12px;
  color: #6e7079;
  margin: 0;
  margin-left: auto;
  text-align: right;
}
