.Project-Create-Page {
  .schemas {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    .schema-card {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}
