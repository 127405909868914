@import "../consts";

.ProjectView {
  height: 100%;
  flex: 1;
  overflow: auto;

  $x: $layoutpaddingsides;

  .ProjectView-Header {
    position: fixed;
    z-index: 1;
    width: 100%;
    padding: 0 $x;
    //background: none;
    background: var(--blings_background);
    border-bottom: 1px solid var(--blings_background2);
  }

  .ProjectView-Content {
    padding: 10px $x;
    margin-top: 64px;
  }

  .project-option-btn {
    .project-option-label {
      margin-left: 0.6rem;
    }
  }

  .ProjectAnalytics_iframe-container {
    margin-top: -25px; // fix 'hides google logo' position
  }

  .loading_iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .project-op-btns {
    margin-bottom: 1rem;
  }

  .JSONForm {
    display: flex;
    flex-direction: column;

    .form-group {
      //align-items: flex-start;

      flex-direction: column;

      &.field-boolean {
        flex-direction: row;
      }
    }

    .ErrorList {
      order: 1;
    }

    .submit-btn {
      order: 2;
    }

    //max-width: 620px;
    overflow: auto;

    .ant-form-vertical {
      max-height: 70vh;
    }

    .ant-form-item {
      margin-bottom: 16px;
    }

    .ant-form-extra {
      padding-top: 0;
    }

    .ant-form-item-label {
      text-align: unset;
    }

    > .ant-form-item {
      overflow: auto;
      background: var(--blings_background2);
      padding: 0 0.6rem;
      box-sizing: border-box;
    }
  }
}

/**


.ProjectView {

  height: 100%;
  overflow: auto;
  flex:1;

  .project-option-btn {
    .project-option-label {
      margin-left: 0.6rem;
    }

  }

  .ProjectAnalytics_iframe-container {
    margin-top: -25px; // fix 'hides google logo' position
  }

  .loading_iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  .project-op-btns {
    margin-bottom: 1rem;
  }

  .JSONForm {
    //max-width: 620px;
    overflow: auto;

    .ant-form-vertical {
      max-height: 70vh;

    }
    .ant-form-item {
      margin-bottom: 16px;
    }
    .ant-form-extra {
      padding-top: 0;
    }

    > .ant-form-item {

    }

    .form-components{
      max-height: 76vh;
      overflow: auto;
      //background: aquamarine;
      background: var(--blings_background2);
      padding: 0 0.6rem;
      box-sizing: border-box;
      margin-bottom: 1rem;
    }


  }
}


 */
