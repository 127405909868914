.project-schemas {
  padding: 0 0 0 2rem;

  .schemas {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    overflow: hidden;

    ._media-card {
      background: var(--ant-color-bg-container) !important;
      border: solid 1px var(--blings_input_gray) !important;
      border-radius: 6px;
      height: 100%;
    }
    .ant-card._media-card {
      margin: auto;
      margin-left: 0;
      height: 100%;
      .ant-card-body {
        height: 100% !important;
        padding: 0;
        overflow: auto !important;
      }
    }
    .content-wrapper {
      height: 100%;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding-right: 10px !important;

      .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }
      .save-btn {
        margin: 10px 0;
      }

      ._main-buttons {
        border-color: #cdd5e5 !important;
      }
      ._header {
        padding-right: 1.5rem;
      }
      ._full-edit-field {
        margin-right: 1.5rem;
      }
    }
    .save-btn-wrapper {
      height: 52px;
      position: sticky;
      bottom: -5px;
      background: white;
      box-shadow: #cdd5e5 -1px -1px 1px 0px;
      width: 100%;
    }
    .schema-card {
      width: 100%;
      overflow: hidden;
      height: calc(100vh - 260px);

      ._main-buttons {
        margin: 0 !important;
        padding: 1rem 1rem 1rem 0 !important;
      }
      .ant-collapse {
        padding: 0 !important;
        ._base-field {
          padding: 6px 1.5rem 6px 0 !important;
        }
      }
      .ant-card {
        overflow: hidden;
        border: none;
        padding: 0;
      }
      .ant-card-head {
        padding: 0%;
      }
      .ant-card-body {
        padding: 0;
        height: calc(100% - 60px);
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
}
