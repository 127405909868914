.usecase-gallery-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: auto;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 12px;
  transition: box-shadow 0.2s ease-in-out;
  max-width: fit-content;

  &:hover {
    box-shadow: 0px 0px 16px 0px #4a474731;
    .video-thumb-container {
      transform: scale(0.988, 0.989);

      .video-thumb {
        transform: translateY(-5px);
      }
    }

    .usecase-details {
      height: 50%;
      .usecase-card-description {
        opacity: 1;
      }
    }
  }

  .image-animation-background {
    height: 100%;
    width: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aspect-ratio: 9/16;
    box-shadow: 0px 0px 0px 20px #ffffff;
  }

  .video-thumb-container {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    height: 100%;
    background: #ffffff;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: none;
    transition: transform 0.3s ease-in-out;
  }

  .video-thumb {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  }

  .usecase-details {
    display: block;
    flex-direction: column;
    gap: 3.5px;
    max-width: 100%;
    position: absolute;
    background: #ffffff;
    bottom: 0;
    overflow: hidden;
    height: 25%;
    padding: 12px 12px 0 12px;
    transition: height 0.3s ease-in-out;

    .usecase-card-title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-word;
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #1b2b4b;
    }
    .usecase-card-description {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      line-height: 1.5;
      text-overflow: ellipsis;
      white-space: normal;
      opacity: 0;
      font-size: 12px;
      font-weight: 400;
      color: #262626;
      font-family: "Inter";
      transition: opacity 0.3s ease-in-out;
      margin-top: 10px;
    }

    .text {
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
