@import "../consts";

.Main-Content {
  position: fixed;
  top: 60px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 60px);
  width: 100%;
  background-color: var(--blings_pages_background);
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  .title-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 1rem 2rem 1rem 2rem;
    height: 60px;
    color: var(--blings_title_and_subtitle);

    .title {
      font-family: "Inter";
      font-weight: 700;
      font-size: 20px;
      line-height: 31.43px;
    }

    .subtitle {
      font-family: "Inter";
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .scene-selector {

    .SceneOrLineupSelect, .view-live-version-select{
      width: 300px;
    }
    
    > span {
      font-size: 14px;
      font-weight: 600;
      color: var(--blings_card_title_gray);
      margin-right: 10px;
    }
  }
}



.ArrowDown {
  color: var(--blings_pink);
  font-size: 16px;
}

.page-layout-padding {
  height: 100%;
  overflow: auto;
}

.account-subdomain {
  margin-bottom: 1rem;
  display: flex;
  width: 20rem;

  .BlingsInput {
    margin-bottom: 0rem;
  }

  .tooltip {
    max-width: 150px;
    align-self: center;
    font-size: 13px;
    margin-left: 8px;
  }
}

.Header {
  .ConnectToProject {
    .ant-typography {
      margin-bottom: 0;
      margin-right: 0.4rem;
      // This should only be show on F1 line
      white-space: nowrap;
    }

    .ant-select-selection-item {
      color: #53545c;
    }
  }

  display: flex;
  align-items: center;
  padding: 0rem calc(2rem + 16px) 0 2rem;
  font-size: 12px;
  height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  overflow: hidden;
  background-color: white;
  white-space: nowrap;
  z-index: 99;
  border-bottom: solid 1px #cdd5e5;

  .ant-select {
    min-width: 12rem;
    align-self: center;
    margin-bottom: 0;

    .ant-select-selection-item {
      align-self: center;
    }
  }

  #Logo {
    cursor: pointer;
    min-width: 32px;
  }

  .arrow-down {
    cursor: pointer;
    display: flex;
    transition: transform 0.3s ease;

    &.selected {
      transform: rotate(180deg);
    }
  }

  .NavBar {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 26px;

    .left {
      display: flex;
      gap: 1.6rem;
    }

    .menu-items {
      display: flex;
      gap: 26px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-items: center;
    }

    .ProjectTitle {
      .project-title-text {
        color: #a6a8b1;
        height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 2px;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      width: 100px;
      font-size: 14px;
      cursor: pointer;
      width: 180px;
      padding: 0 12px;
      border: 1px solid transparent;
      border-image-source: linear-gradient(to bottom,
        transparent 20%,
        #e1e2e9 20%,
        #e1e2e9 80%,
        transparent 80%);
      border-image-slice: 1;
    }

    .LogoLink {
      align-self: center;
    }

    .ProjectSearch {
      .ant-select-selector {
        padding: 0 11px 0 0;
        border-radius: 9px;
        background: inherit;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid rgba(255, 21, 77, 0.1);
        }

        .ant-select-selection-item {
          margin: 0;
        }
      }
    }

    .HomePage {
      font-size: 14px;
    }

    .NavItem {
      &::after {
        // To avoid spacing changes when selected item becomes bold on select
        display: block;
        content: attr(id);
        font-weight: bold;
        height: 1px;
        color: transparent;
        overflow: hidden;
        visibility: hidden;
      }
    }

    .Selected {
      color: var(--blings_pink);
      font-weight: 700;
      // text-decoration: underline;
    }
  }

  #Title {
    margin: 0 1rem 0 0;
    color: var(--blings_pink);
    font-weight: 700;
    // font-size: 1rem;
    align-self: center;
  }

  #PreviewButton {
    padding: 12px 0;
    height: 100%;
    display: flex;

    .LiveVersion {
      display: flex;
      align-items: center;
    }

    height: 100%;
    margin-left: auto;

    .DownArrow {
      margin-left: 0.5rem;
    }
  }

  .previewPublished-short {
    display: none;
  }

  @media screen and (max-width: 90rem) {
    .previewPublished-short {
      display: block;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .previewPublished-long {
      display: none;
    }
  }

}

.view-live-version-popover {
  .view-live-version-select {
    min-width: 150px;

    .ant-select-selector {
      margin-right: 0.4rem;
      padding: 0.2rem 1.2rem 0.2rem 0.7 rem;

      border: solid 1px var(--blings_input_gray) !important;

      &:hover {
        border: solid 1px var(--blings_input_focus) !important;
      }
    }
  }
}

.RightNav {
  .user-email {
    color: #130f26;
    font-size: 14px;
  }

  .preview {
    border: none !important;
    background: white !important;
    display: flex;
    color: #1b2b4b;
    padding: 3px 5px 0 5px !important;
    align-items: center;

    &:hover {
      border: none !important;
      color: var(--blings_pink_hover) !important;
    }
  }

  .settings {
    color: #1b2b4b;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      color: var(--blings_pink);
    }

    height: 80%;
    cursor: pointer;
  }

  .notificationIcon {
    color: #1b2b4b;
    margin-top: 2px;

    &>div {
      height: 100%;
    }

    height: 100%;

    .ant-badge {
      font-size: 20px;
      display: flex;
    }
  }

  gap: 0.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-left: auto;

  #ExtraInfo-short {
    display: none;
    color: #1b2b4b;
    transition: all 0.15s ease-in-out;

    &:hover {
      color: #ff3666;
      transition: all 0.15s ease-in-out;
    }
  }

  #ExtraInfo-long {
    height: 100%;
    color: #8b8d97;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 0.5rem;
  }

  @media screen and (max-width: 100rem) {
    #ExtraInfo-short {
      display: block;
      height: 100%;
      display: flex;
      align-items: center;
    }

    #ExtraInfo-long {
      display: none;
    }
  }
}

.overlayClass {

  &,
  .ant-popover-placement-bottomRight {
    padding-top: 0;
  }

  .ant-popover-inner-content {
    padding: 12px 16px 0 16px;
  }
}

.RenderStep {
  .dot1 {
    animation: visibility 3s linear infinite;
  }

  @keyframes visibility {
    0% {
      opacity: 1;
    }

    65% {
      opacity: 1;
    }

    66% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  .dot2 {
    animation: visibility2 3s linear infinite;
  }

  @keyframes visibility2 {
    0% {
      opacity: 0;
    }

    21% {
      opacity: 0;
    }

    22% {
      opacity: 1;
    }

    65% {
      opacity: 1;
    }

    66% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  .dot3 {
    animation: visibility3 3s linear infinite;
  }

  @keyframes visibility3 {
    0% {
      opacity: 0;
    }

    43% {
      opacity: 0;
    }

    44% {
      opacity: 1;
    }

    65% {
      opacity: 1;
    }

    66% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }
}