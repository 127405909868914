.blings-card {
  padding: 0px 30px !important;

  .blings-card-title{
    font-weight: 700;
    font-family: "Inter";
    font-size: 18px;
    line-height: 20px;
    color: var(--blings_card_title_gray);
    height: 60px !important;
    min-height: 60px !important;
    display: flex;
    align-items: center;
  }

  .large{
    font-size: 18px;
  }

  .medium{
    font-size: 16px;
  }

  .small{
    font-size: 14px;
  }

  .ant-card-head{
    padding: 0 !important;
  }

  .ant-card-head-title{
    color: var(--blings_card_title_gray);
  }

  .ant-card-body {
    padding: 0;
    padding-bottom: 20px;
  }
}

