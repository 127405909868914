.BlingsAnchorButton {
  text-decoration: underline;
  color: var(--blings_that_gray);
}

.BlingsAnchorButton.disabled,
.BlingsAnchorButton[aria-disabled="true"] {
  pointer-events: none;
  color: #cccccc;
  cursor: default;
  text-decoration: none;
}

.BlingsAnchorButton:hover,
.BlingsAnchorButton:focus,
.BlingsAnchorButton[aria-pressed="true"] {
  text-decoration: underline;
  color: var(--blings_pink);
}
