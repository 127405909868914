.carousel {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-image {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  aspect-ratio: 9 / 16;
}

.carousel-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  flex-shrink: 0;
  border-radius: 12px;
}

.carousel-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20px;
  margin-top: 16px;
}

.arrow {
  background: none;
  border: none;
  color: #a6a8b1;
  display: flex;
  padding: 0 10px;
  cursor: pointer;
  &:hover {
    color: #1e1e1e;
  }
}

.arrow:focus {
  outline: none;
}

.dots {
  display: flex;
  gap: 0.5rem;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #a6a8b1;
}

.dot.active {
  background-color: #1e1e1e;
}
