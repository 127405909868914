.CsvIntegration {
  display: flex;
  column-gap: 5rem;
  flex-wrap: wrap;
  row-gap: 2rem;
  .ant-form {
    .ant-form-item-explain {
      position: absolute;
      bottom: -2px;
      left: 2px;
      font-size: 13px;
    }
    .form-item-scene {
      .ant-form-item-explain {
        font-size: 13px;
      }
    }
  }
  .ant-form {
    .ant-form-item-with-help .ant-form-item-explain:not(.form-item-scene) {
      position: absolute;
      bottom: -2px;
      left: 2px;
      font-size: 13px;
    }
  }
  .form-subtitle {
    font-weight: 700;
    font-size: 12px;
    color: var(--blings_purple);
    margin-bottom: 0.5rem;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .DataIntegration {
    display: flex;
    flex-direction: row;
    width: 42rem;

    .video-container {
      margin: auto;
      padding: 0 5rem;
    }
  }
  .csv-selection-extra {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
