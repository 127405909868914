.FormData {
  padding: 0 2rem 20px 2rem;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  .ant-table-thead {
    > tr > th {
      background: #f2f4f8;
    }
  }

  .table-wrapper {
    overflow: hidden;
    height: 100%;
  }
  .top {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 20px;
    width: 100%;
    position: absolute;
    top: 1.5rem;
    right: 2rem;
  }
}
