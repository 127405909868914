.Update-Project {
  padding: 0 0 0 2rem;
  .ant-card {
    border: none !important;
    background: none !important;
    padding: 0 10px 0 0 !important;
  }
  .ant-card-body {
    padding: 0 !important;
    form .ant-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-direction: row;

      .ant-form-item-label {
        width: 140px;
        display: flex;
      }
      .BlingsInput {
        padding: 0 !important;
      }
    }
  }
  .schemas {
    margin: 1rem 0;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    .schema-card {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
  .BlingsBtn {
    margin-bottom: 1rem;
  }
  .Icon {
    cursor: pointer;
    color: #212121;
    background-color: #7d959c33;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    &.enabled {
      &:hover {
        color: var(--blings_pink);
        background-color: #fbe9ed;
      }
    }
    &.disabled {
      cursor: default;
      background-color: #a9b6c333;
      color: #cccccc;
    }
  }
}
.project-schemas {
  .BlingsBtn {
    margin-bottom: 1rem;
    font-weight: bold;
  }
}
