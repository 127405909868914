.mute-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  :hover {
    cursor: pointer;
  }
}

.mute-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: fit-content;
 color: white;

  .mute-btn {
    font-size: 24px;
    border-radius: 50%;
    padding: 0.5rem;
    z-index: 1; // Ensure the icon appears above the line
  }

  .mute-line {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute; // This is only for the line, not the icon
    top: 50%;
    transform: translateY(-50%);
    rotate: 135deg;
    z-index: 1; // Ensure the line is behind the icon
  }
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}
