.settings-page {
  display: flex;
  width: 100%;
  padding: 0 0 0 2rem;

  .player-load {
    width: 100%;
  }
  .player-settings {
    height: calc(100vh - 250px);
    width: 100%;
    .ant-divider-horizontal.divider {
      margin: 1.5rem 0;
    }
    .ant-card-body {
      .form-item {
        .card-text {
          width: 200px;
        }
        display: flex;

        justify-content: flex-start;
      }
      .input-item {
        display: flex;
        width: 100%;
        padding-right: 10px;
        justify-content: space-between;
      }
    }
    .color-customization {
      .tooltip {
        display: flex;
        align-items: center;
        align-self: center;
        font-size: 13px;
        margin-left: 2rem;
      }
      display: flex;
      flex-direction: row;
      gap: 5rem;
      .col {
        gap: 0.5rem;
        flex-direction: column;
        display: flex;
      }
      .row {
        gap: 0.5rem;
        flex-direction: column;
        display: flex;
      }
      .sub-header {
        color: var(--blings_teal_60);
        text-decoration: underline;
        display: flex;
      }
      .form-item {
        color: var(--blings_card_text_gray) !important;
        align-items: center;
        label {
          width: 6rem;
        }
      }
    }
    .playback {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      label {
        color: var(--blings_card_text_gray) !important;
        margin-left: 0;
        &:last-child {
          margin-right: 0;
        }
      }
      .delayed-autoplay {
        font-size: 13px;
        display: inline-flex;
        align-items: center;
        margin: 0.5rem 0;
        .delayed-autoplay-input {
          width: 3rem;
          margin: 0 0.5rem;
        }
      }
      .autoplay-options {
        .tooltip-1 {
          display: flex;
          align-items: center;
          align-self: center;
          font-size: 13px;
          margin-left: 8px;
        }
        .radio-group {
          height: 3rem;
          display: flex;
          align-items: center;
          .last-child {
            & > span {
              padding-right: 0;
            }
          }
        }
        .autoplay {
          margin-bottom: 0.5rem;
        }
      }
    }
    .timeline-settings {
      display: flex;
      gap: 1rem;
      .form-item {
        align-items: center;
        color: var(--blings_card_text_gray) !important;
      }
    }
    .subtitle {
      font-weight: 600;
      color: var(--blings_card_title_gray);
      margin-bottom: 1rem;
    }
    .form-item {
      color: var(--blings_card_text_gray) !important;
      display: flex;
      flex-wrap: nowrap;
      align-items: baseline;
      gap: 0.5rem;
      &.disabled {
        opacity: 0.4;
      }
    }
  }

  .text-disabled {
    opacity: 0.4;
  }

  .ant-card {
    border: none !important;
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
  .ant-card .ant-card-body {
    height: 100%;
    padding: 0 !important;
    padding-right: 10px !important;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &::-webkit-scrollbar {
      width: 14px !important;
    }

    &::-webkit-scrollbar-thumb {
      border: solid 3px transparent !important;
      background-clip: padding-box !important;
    }
  }
}
.card-text {
  color: var(--blings_card_text_gray);
}
.tab-player-container {
  position: absolute;
  top: -54px;
  left: 600px;
  width: calc(-660px + 100vw);
  max-width: 100%;
  height: calc(-175px + 100vh);
}
.save-btn-wrapper {
  height: 52px;
  position: sticky;
  bottom: -5px;
  background: white;
  box-shadow: #cdd5e5 -1px -1px 1px 0px;
  width: 100%;
}
.save-btn {
  margin: 10px 0;
}
