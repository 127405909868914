.BlingsButton {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 32px;
  background-color: var(--blings_pink);
  padding: 0.4rem 1.2rem;
  outline: none;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  color: white;
  &:hover {
    background-color: var(--blings_pink_hover);
  }
  &.Disabled {
    background-color: #ff154d40;
    cursor: not-allowed;
    pointer-events: none;
  }
  &.Outlined {
    font-weight: 400;
    border: 1.2px solid var(--blings_pink);
    background-color: white;
    color: var(--blings_pink);
    &:hover {
      background-color: var(--blings_btn_hover);
      color: var(--blings_pink);
      border: 1.2px solid var(--blings_pink);
    }
  }
}
